import { Injectable } from '@angular/core';

@Injectable()
export class AuthConfigService {

  config: any = {};


  private admin_vendor1 = {
    // sib-admin-api
    authApiUrl: 'https://admin-vendor1.auth0.com/api/v2/',
    apiAccessTokenUrl: 'https://admin-vendor1.auth0.com/oauth/token',
    apiConfigCredentials: {
      'grant_type': 'client_credentials',
      'client_id': '536AUGCwk0YU7ybOiRqTdJotPWoR6R3q',
      'client_secret': 'IkPjpDBG81NBe0Z-T0VhanJkVZsbXUZ1uF11StZm9JpF9UwnMaygpYmbjEJPIS_y',
      'audience': 'https://admin-vendor1.auth0.com/api/v2/'
    },

    // auth0-authorization-extension-api(Test Application)
    authUserManagementConfig: {
      'client_id': 'zL25o4aT988An3HL5634Ho6gsP3MoaZ5',
      'client_secret': '60f0oNkMnKtu95yxn3Q26EkYu4i9b3v6lpnINylsLw9sjFxBMHU03F3FZbOpSA8L',
      'audience': 'urn:auth0-authz-api',
      'grant_type': 'client_credentials'
    },

    // admin-showitbig
    authConfig: {
      clientID: '493pg3Wg5u322M213qsnrcrt2MJPxghr',
      // clientId : 'rEBP8b12yPBEZmkDMnA9l8D9nffKkApv',
      domain: 'admin-vendor1.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-admin-api',
      redirectUri: 'https://admin-vendor1.showitbig.com/',
      scope: 'openid'
    }
  };

  private devEnvironment = {
    authApiUrl: 'https://admin-showitbig-dev.auth0.com/api/v2/', // auth0 management api identifier
    apiAccessTokenUrl: 'https://admin-showitbig-dev.auth0.com/oauth/token', // auth0 authz generic application token url in endpoints
    apiConfigCredentials: { // custom api - sib-admin-dev-api
      grant_type: 'client_credentials',
      client_id: '0rVbHxoskb3z6DGzILUh7WnQ4pGKAcVS',
      client_secret: 'jESzo6GQomukNudsqckoH9BWPfuGheHr0Esj6ubPnsSpqLsitlG40C0At8w9YmNs',
      audience: 'https://admin-showitbig-dev.auth0.com/api/v2/' // same as the authapiurl
    },

    authConfig: { // application admin-showitbig
      clientID: 'ljz6BFzueR9lOjZBdADu5BDxA1bLd65K',
      domain: 'admin-showitbig-dev.auth0.com',
      responseType: 'token id_token',
      audience: 'sib-admin-dev-api', // custom api created for application
      redirectUri: 'https://admin-dev.showitbig.com', // allowed web origin of audience
      scope: 'openid'
    }
  };

  constructor(
    // private sibMasterService: SibMasterService
  ) {
    if (document.location.href.indexOf('admin-vendor1') > -1) {
      this.config = this.admin_vendor1;
    } else if (document.location.href.indexOf('admin-dev.showitbig.com') > -1) {
      this.config = this.devEnvironment;
    }


  }
}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userProfile: any;
  webConfig: {
    clientID: any,
    domain: any,
    responseType: any,
    audience: any,
    redirectUri: any,
    scope: any
  };

  authConfig: {
    grant_type: string,
    client_id: string,
    client_secret: string,
    audience: string;
  };

  usermngmntConfig: {
    client_id: string,
    client_secret: string,
    audience: string,
    grant_type: string
  };

  constructor(
    private auth: AuthService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {

    // Programmer: Sanchit Mirg, Date: 09-25-2017
    // next is json object which contains fragment from which we get the authtoken,
    // if it exist in the fragment then calling the handleauthentication method to set 
    // the userProfile and token in local storage 
    if (next.fragment) {
      this.auth.handleAuthentication();

    } else if (this.auth.isAuthenticated()) {
      return true;
    } else {
      // this.sibMasterService.getMasterAccessToken().subscribe((masterAccessToken) => {
      //   localStorage.setItem('master_access_token', masterAccessToken['access_token']);
      //   this.sibMasterService.getAuthConfig(this.domain(document.location.href)).subscribe((authConfig) => {
      //     localStorage.setItem('auth-web-config', JSON.stringify(this.createAuthConfig(authConfig['webConfig'])));
      //     localStorage.setItem('mobile-config-client-id', authConfig['mobileConfig']['clientID'])
      //     localStorage.setItem('web-config-client-id', authConfig['webConfig']['clientID'])
      //     localStorage.setItem('authApiUrl', authConfig['webConfig']['authApiUrl']);
      //     localStorage.setItem('apiAccessTokenUrl', authConfig['webConfig']['apiAccessTokenUrl']);
      //     localStorage.setItem('auth-api-config', JSON.stringify(this.createAuthApiConfig(authConfig['sibConfig'])))
      //     localStorage.setItem('id', authConfig['code'])
      // tslint:disable-next-line: max-line-length
      //     localStorage.setItem('usermanagement-config', JSON.stringify(this.createUserMngtConfig(authConfig['authUserManagementConfig'])))
      //     localStorage.setItem('auth_permission_url', authConfig['authUserManagementConfig']['authPermissionUrl'])
      //     this.sibMasterService.setAuthWebConfig(this.createAuthConfig(authConfig['webConfig']));
      //     this.auth.setConfig(this.createAuthConfig(authConfig['webConfig']));
      this.auth.login();
      // });
      // });
      // this.auth.login();
      return false;
    }
  }

}
